import { configureStore } from '@reduxjs/toolkit'
import perPage from './slices/perPage/perPage'
import data from './slices/data/data.js'
import pages from './slices/pages/pages'
import dataTemplates from './slices/dataTemplates/dataTemplates'
import dataEmpresas from './slices/dataEmpresas/dataEmpresas'
import dataUsuarios from './slices/dataUsuarios/dataUsuarios'
import dataDisp from './slices/dataDisp/dataDisp'

export default configureStore({
    reducer:{
        perPage,
        data,
        pages,
        dataTemplates,
        dataEmpresas,
        dataUsuarios,
        dataDisp
    }
})
