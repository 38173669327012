import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import '../../static/css/GrillaControls.css'

const GrillaControls = () => {
    const { listPage: pages } = useSelector(state => state.pages)
    const { filteredData } = useSelector(state => state.data)
    const { perPage } = useSelector(state => state.perPage)
    
    const getData = (page, per_page) => {
        const start_index = page - 1;
        const start = start_index * per_page;
        const total_amount = (page * per_page);
        console.log('render') 
        return filteredData.slice(start, total_amount)
    }
    
    const value = getData(pages, perPage);

    return (
        <div style={{overflowX: "auto"}}>
            <table className="table">
                <thead>
                    <tr className='headers'>
                        <th className="headersTextmin" width="5%">#</th>
                        <th className="headersText" width="25%">Fecha</th>
                        <th className="headersText" width="25%">Dispositivo</th>
                        <th className="headersText" width="25%">Plantilla</th>
                        <th className="headersText" width="20%"></th>
                    </tr>
                </thead>
                <tbody>
                { value.length === 0 
                ? (
                    <tr>
                        <td className="headersText">Sin registros para periodo seleccionado</td>
                    </tr>
                )
                : value.map((element, index)=>{
                    const date = new Date(element.FechaDeControl)
                    // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute:'numeric' };
                    const transformDate = date.toLocaleString(date)
                    return(
                            <tr key={index} className="fraction">
                                <td className="tdTextmin" width="5%">{element.nControl}</td>
                                <td className="tdText" width="25%">{transformDate}</td>
                                <td className="tdText" width="25%">{element.Dispositivo}</td>
                                <td className="tdText" width="25%">{element.Plantilla}</td>
                                <td className="tdText" width="20%">
                                    <Link className="link" to={`/viewcontroldetail/${element.nControl}`}>
                                        <button className="buttonDetails">Ver Detalle</button>
                                    </Link>
                                </td>
                            </tr>
                    )
                })}
                </tbody>
                <tfoot>
                    <tr className='spaceWithe' >
                        <td>
                            <br />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default GrillaControls