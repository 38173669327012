import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import '../../static/css/GrillaControls.css'

const GrillaUsuarios = ({mobile, nombresEmpresas}) => {
    
    const { filteredData } = useSelector(state => state.dataUsuarios)
    const { listPage: pages } = useSelector(state => state.pages)
    const { perPage } = useSelector(state => state.perPage)

    const [ user, setUser ] = useState({});
    const [ inputEmp, setInputEmp] = useState('')
    const [ inputNombre, setInputNombre ] = useState('')
    const [ inputEmail, setInputEmail ] = useState('')

    const getData = (page, per_page) => {
        const start_index = page - 1;
        const start = start_index * per_page;
        const total_amount = (page * per_page);
        return filteredData.slice(start, total_amount)
    }
    
    const value = getData(pages, perPage);

    const showEditModal = (user) => {
        setUser(user)
        setInputNombre(user.nombre)
        setInputEmp(user.empresa)
        setInputEmail(user.email)
        const editDialog = document.getElementById("editDialog");
        editDialog.showModal();
    }

    const showDeleteModal = (user) => {
        setUser(user)
        const deleteDialog = document.getElementById("deleteDialog");
        deleteDialog.showModal();
    }

    return (
        <div style={{overflowX: "auto"}}>

            <dialog className='dialog' id="editDialog">  
                <form>
                    <div className='titleModal'>
                        <h4>Editar Usuario</h4>
                    </div>
                    <div className="contentModal">
                        <label className='inputModal'>Nombre:
                            <input className='inputSearchModal' value={inputNombre} type="text" onChange={(e) => setInputNombre(e.target.value)}></input>
                        </label>

                        <label className='inputModal'>Email:
                            <input className='inputSearchModal' value={inputEmail} type="text" onChange={(e) => setInputEmail(e.target.value)}></input>
                        </label>

                        <label className='inputModal'>Empresa:
                            <select id="selectEmpresa" className='inputSearchModal' value={inputEmp} onChange={(e) => setInputEmp(e.target.value)}>
                                {nombresEmpresas.map((nombre) => { 
                                    return <option key={nombre} value={nombre}>{nombre}</option>
                                })}
                            </select>
                        </label>
                    </div>
                    <div className='buttonsModal'>
                        <button className='buttonModal' value="cancel" formMethod="dialog">Cancelar</button>
                        <button className='buttonModal' style={{marginLeft: '0.5rem'}} id="confirmBtn" value="default">Confirmar</button>
                    </div>
                </form>
            </dialog>

            <dialog className='dialog' id="deleteDialog">  
                <form>
                    <div className='titleModal'>
                        <h4>Eliminar Usuario</h4>
                    </div>
                    <div className="contentModal">
                        <p>Al eliminar el usuario se eliminarán los controles asociados</p>
                        <p>¿Realmente desea eliminar al Usuario: <b>{user.nombre}</b>?</p>
                    </div>
                    <div className='buttonsModal'>
                        <button className='buttonModal' value="cancel" formMethod="dialog">Cancelar</button>
                        <button className='buttonModal' style={{marginLeft: '0.5rem'}} id="confirmBtn" value="default">Eliminar</button>
                    </div>
                </form>
            </dialog>

            <table className="table">
                <thead>
                    <tr className='headers'>
                        <th className="headersTextmin" width="10%">#</th>
                        <th className="headersText" width="25%">Nombre</th>
                        <th className="headersTextXL" width="25%">E-mail</th>
                        <th className="headersText" width="25%">Empresa</th>
                        <th className="headersText" width="15%"></th>
                    </tr>
                </thead>
                <tbody>
                { value.length === 0 
                ? (
                    <tr>
                        <td className="headersText">Sin registros</td>
                    </tr>
                )
                : value.map((element)=>{
                    return(
                        <tr key={element.id} className="fraction">
                            <td className="tdTextmin" width="10%" style={{textAlign: "left"}}>{element.id}</td>
                            <td className="tdText" width="25%" style={{textAlign: "left"}}>{element.nombre}</td>
                            <td className="tdTextXL" width="25%" style={{textAlign: "left"}}>{element.email}</td>
                            <td className="tdText" width="25%" style={{textAlign: "left"}}>{element.empresa}</td>
                            <td className="tdText" width="15%">
                                <button className="buttonDetails" onClick={() => showEditModal(element)}>Editar</button>
                                <button className="buttonDetails" style={{marginLeft: "0.5rem", backgroundColor: "#FF9B85"}} onClick={() => showDeleteModal(element)}>Eliminar</button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
                <tfoot>
                    <tr className='spaceWithe' >
                        <td>
                            <br />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default GrillaUsuarios