import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import '../../static/css/GrillaControls.css'
import { decimalWithComma } from '../../utils/utils.js'

const GrillaTemplates = () => {
    const { listPage: pages } = useSelector(state => state.pages)
    const { filteredData } = useSelector(state => state.dataTemplates)
    const { perPage } = useSelector(state => state.perPage)

    const [item, setItem] = useState(null);
    
    const getData = (page, per_page) => {
        const start_index = page - 1;
        const start = start_index * per_page;
        const total_amount = (page * per_page);
        return filteredData.slice(start, total_amount)
    }
    
    const value = getData(pages, perPage);

    return (
        <div style={{overflowX: "auto"}}>
            <table className='table' >
                <thead>
                    <tr className='headers'>
                        <th className="headersTextmin" width="2.5%">#</th>
                        <th className="headersText" width="15%">Fecha de Creación</th>
                        <th className="headersText" width="20%">Dispositivo</th>
                        <th className="headersText" width="15%">Plantilla</th>
                        <th className="headersText" width="15%">Unidad de Medida</th>
                        <th className="headersText" width="15%">Tipo de medida</th>
                        <th className="headersTextmin" width="2.5%"></th>
                        <th className="headersText" width="15%"></th>
                    </tr>
                </thead>
                <tbody>
                { value.length === 0 
                ? (
                    <tr>
                        <td className="headersText">Sin registros para periodo seleccionado</td>
                    </tr>
                )
                : value.map((element)=>{
                    const date = new Date(element.FechaDeCreacion)
                    const transformDate = date.toLocaleString(date)
                    return(
                        <>
                            <tr key={element.nPlantilla} className="fraction">
                                <td className="tdTextmin" width="2.5%">{element.nPlantilla}</td>
                                <td className="tdText" width="15%">{transformDate}</td>
                                <td className="tdText" width="20%">{element.Dispositivo}</td>
                                <td className="tdText" width="15%">{element.Plantilla}</td>
                                <td className="tdText" width="15%">{element.Unidad}</td>
                                <td className="tdText" width="15%">{element.Medidas.reduce((acc, e) => acc + e.Nombre + "-", "").slice(0, -1)}</td>
                                {
                                    element.nPlantilla !== item ?
                                        <td className="tdTextmin" width="2.5%">
                                            <button className="buttonSubtable" onClick={() => setItem(element.nPlantilla)}>v</button>
                                        </td>
                                    : <td className="tdTextmin" width="2.5%">
                                        <button className="buttonSubtable" onClick={() => setItem(null)}>-</button>
                                    </td>
                                }
                                <td className="tdText" width="15%">
                                    <Link className="link" to={`/report/${element.nPlantilla}`}>
                                        <button className="buttonDetails">Consolidado</button>
                                    </Link>
                                </td>
    
                            </tr>

                            { element.nPlantilla === item ? ( 
                            <>
                                <tr key={element.nPlantilla + ' subheader'} className='subheaders'>
                                    <th className='subheadersText' width="20%">Nombre Medida</th>
                                    <th className='subheadersText' width="20%">Cantidad de mediciones</th>
                                    <th className='subheadersText' width="20%">Objetivo</th>
                                    <th className='subheadersText' width="20%">Tolerancia Superior</th>
                                    <th className='subheadersText' width="20%">Tolerancia Inferior</th>
                                </tr>
                                
                                {element.Medidas.map((element) => {
                                    return (
                                        <tr key={element.Nombre} className="subfraction">
                                            <td className="tdText" width="20%">{element.Nombre}</td>
                                            <td className="tdText" width="20%">{element.Cantidad}</td>
                                            <td className="tdText" width="20%">{decimalWithComma(element.Objetivo)}</td>
                                            <td className="tdText" width="20%">{decimalWithComma(element.ToleranciaSuperior)}</td>
                                            <td className="tdText" width="20%">{decimalWithComma(element.ToleranciaInferior)}</td>
                                        </tr>
                                    )
                                })}
                            </>
                            ) : null }
                        </>
                    )
                })
            }
                

                </tbody>
                <tfoot>
                    <tr className="spaceWithe" >
                        <td>
                            <br />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default GrillaTemplates