import React, { useEffect } from 'react'
import GrillaControls from '../componentes/controls/GrillaControls'
import PanelSelector from '../componentes/controls/PanelSelector'
import arrowRight from '../static/images/arrowRight.png'
import SlidePage from '../componentes/controls/SlidePage'
import '../static/css/styleControl.css'
import { setPerPage } from '../store/slices/perPage/perPage'
import { setPagesList } from '../store/slices/pages/pages'
import { useDispatch, useSelector } from 'react-redux'
import Sidebar from '../componentes/controls/Sidebar'
// import { fetchData } from '../store/slices/data/data'

const ViewControl = () => {

    const { perPage: perpage } = useSelector(state => state.perPage)
    const lengthFiltered = useSelector(state => state.data.filteredData.length) 
 
    const dispatch = useDispatch()

    useEffect(() => {
        // dispatch(fetchData())
    }, [])

  return (
    <div className="contentViewControl">
        <Sidebar page="viewcontrol"/>
        <div className="backgroundViewControl">
            <p className='location'>Usted está en : Login 
                <img alt='' src={arrowRight}/> 
                <span className="span">Historial de Mediciones</span> 
            </p>
            <h2 className="title">Historial de Mediciones</h2>
            <div className="contentFilter">
                <div className="panel">
                    <PanelSelector/>    
                </div>

                <div className="select">
                    <label htmlFor="rows">Registros por página:</label>
                    <select className="selectPages" defaultValue={perpage} id="rows" onInput={
                        (e) => 
                        {dispatch(setPerPage(e.target.value));
                        dispatch(setPagesList(1))}}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                    </select>
                </div>
            </div>
            <SlidePage datalen={lengthFiltered}/>
            <GrillaControls/>
            <SlidePage datalen={lengthFiltered}/>
        </div>
    </div>
  )
}

export default ViewControl