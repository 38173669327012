import {createSlice} from '@reduxjs/toolkit'

export const pagesList = createSlice({
  name:'pages',
  initialState:{
    listPage:1,
  },
  reducers:{
    setPagesList : (state, action)=>{
      state.listPage = action.payload
  }
  }
})

export const { setPagesList } = pagesList.actions;
export default pagesList.reducer;