import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setFiltered } from '../../store/slices/data/data'
// import { fetchData } from '../../store/slices/data/data'
import { setPagesList } from '../../store/slices/pages/pages'
import { validateRangeDates } from '../../utils/utils'
import '../../static/css/PanelSelector.css'

const PanelSelector = () => {
    const { allData : data } = useSelector(state=>state.data)
    const dispatch = useDispatch()

    const now = new Date().toLocaleDateString('en-CA')
    const maxRangeDate = 2 //months

    const [ dateFrom, setDateFrom ] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString('en-CA'))
    const [ dateTo, setDateTo ] = useState(now)

    useEffect(() => {
        // dispatch(fetchData)
        const fechaDesde = new Date(`${dateFrom}T00:00:00`);
        const fechaHasta = new Date(`${dateTo}T23:59:59`);
        const sortedData = data.slice().sort((a, b) => new Date(b.FechaDeControl).getTime() - new Date(a.FechaDeControl).getTime())
        const filtered = sortedData.filter(item => {
            const fechaControl = new Date(item.FechaDeControl);
            return (fechaControl >= fechaDesde && fechaControl <= fechaHasta)
        });
        dispatch(setPagesList(1));
        dispatch(setFiltered(filtered));
    }, [dateFrom, dateTo, data, dispatch])

    const checkDates = () => {
        const FD = document.getElementById("FechaDesde");
        const FH = document.getElementById("FechaHasta");

        if(validateRangeDates(FD.value, FH.value, maxRangeDate)){
            FD.setCustomValidity("")
        }
        else FD.setCustomValidity("Rango de fechas superior a 2 meses")
    }
    
    return (
        <form className="contentSelector" method="post" onSubmit={(e)=>{
            e.preventDefault()
            if(validateRangeDates(e.target[0].value, e.target[1].value, maxRangeDate)) {
                setDateFrom(e.target[0].value)
                setDateTo(e.target[1].value)
            }
        }}>
     
            <div className="individual">
                <label className='m-2 ' htmlFor='FechaDesde'>Desde</label>
                <input id='FechaDesde' className="input" 
                    type="date" defaultValue={dateFrom} onChange={() => checkDates()} min="2010-01-01" max={now} required
                />
            
            </div>
            <div className="individual">
                <label className='m-2 ' htmlFor='FechaHasta'>Hasta</label>
                <input id='FechaHasta' className="input"
                    type="date" defaultValue={dateTo} onChange={() => checkDates()} min="2010-01-01" max={now} required
                />
            </div>
            <button type="submit" className="buttonClick">Buscar</button>
        </form>
    )
}

export default PanelSelector