import {createSlice} from '@reduxjs/toolkit'

export const perPage = createSlice({
  name:'perPage',
  initialState:{
    perPage: 10,
  },
  reducers:{
    setPerPage : (state, action)=>{
      state.perPage = action.payload
  }
  }
})

export const { setPerPage } = perPage.actions;
export default perPage.reducer;