import React, { useEffect, useRef, useState} from "react";
import AL from '../../static/images/slideArrowLeft.png'
import AR from '../../static/images/slideArrowRight.png'
import '../../static/css/styleSlide.css'
import { setPagesList } from "../../store/slices/pages/pages";

import { useDispatch, useSelector } from "react-redux";

const SlidePage = ({datalen}) => {

  const mobile = 767

  const slideshow = useRef(null);

  const dispatch = useDispatch()

  const { listPage: page } = useSelector(state => state.pages)
  const { perPage: perpage } = useSelector(state => state.perPage)

  const quantityPage = Number.isInteger(datalen/ perpage) ? parseInt(datalen/ perpage) : parseInt(datalen / perpage) + 1

  useEffect( () => {
    const maxSlide = window.innerWidth < mobile ? 4 : 7;
    const slideLength = quantityPage < maxSlide ? quantityPage : maxSlide;
    const first = page - 1 + slideLength < quantityPage ? page - 1 : quantityPage - slideLength;
    const newSlides = datalen === 0 ? [0] : Array.from(Array(quantityPage).keys()).slice(first, first + slideLength);
    setSlides(newSlides);
  },[quantityPage, page, datalen])

  const [slides, setSlides] = useState([]);

  const showSlide = () => {

    return (
      slides.map(i => {
        return(
          <div key={i} className="slide" style={{backgroundColor: i+1 === page ? "#ffc107" : null}}
          onClick={(e) => {
            e.preventDefault();
            setPage(i+1);
          } }>
            {i+1}
          </div>
        )}
      )
    )
  }

  const siguiente = () => {
    const nextPage = page >= quantityPage ? page : page + 1;
    dispatch(setPagesList(nextPage))
  }

  const anterior = () => {
    const nextPage = page <= 1 ? page : page - 1;
    dispatch(setPagesList(nextPage))
  }

  const setPage = (newpage) => dispatch(setPagesList(newpage))

  return (
    <div className="contentSlide">
        <div className="text">
            <p>Página {page} de {quantityPage === 0 ? 1 : quantityPage}</p>
        </div>
        <div className="principalContent">

            <button className="buttonNextL" onClick={anterior}>
                <img src={AL} alt=""/>
            </button>
          
            <div className="contentSlideShow" ref={slideshow}>

                {showSlide()}
          
            </div>

            <button className="buttonNextR" onClick={siguiente}>
                <img src={AR} alt=""/>
            </button>
        </div>
        <div className="nElements">
          <p>Total: {datalen} Registros</p>
        </div>
    </div>
  );
};



export default SlidePage;