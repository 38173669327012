import React from 'react'
import GrillaTemplates from '../componentes/controls/GrillaTemplates'
import PanelSelectorTemplates from '../componentes/controls/PanelSelectorTemplates'
import arrowRight from '../static/images/arrowRight.png'
import SlidePage from '../componentes/controls/SlidePage'
import '../static/css/styleControl.css'
import { setPerPage } from '../store/slices/perPage/perPage'
import { setPagesList } from '../store/slices/pages/pages'
import { useDispatch, useSelector } from 'react-redux'
import Sidebar from '../componentes/controls/Sidebar'

const TemplatesMade = () => {

    const { perPage: perpage } = useSelector(state => state.perPage)
    const lengthFiltered = useSelector(state => state.dataTemplates.filteredData.length)

    const dispatch = useDispatch()

    const style = {
        select:{
            flex: "1 1 20%",
        },
        panel:{
            flex: "1 1 80%"
        },    
    }

    return (
        <div className="contentViewControl">
            <Sidebar page="templates"/>
            <div className="backgroundViewControl">
                <p className='location'>Usted está en : Login 
                    <img alt='' src={arrowRight}/> 
                    <span className="span">Plantillas Creadas</span> 
                </p>
                <h2 className="title">Plantillas Creadas</h2>
                <div className="contentFilter">
                    <div className="panel" style={style.panel}>
                        <PanelSelectorTemplates/>
                    </div>

                    <div className="select" style={style.select}>
                        <label htmlFor="rows">Registros por página:</label>
                        <select className="selectPages" defaultValue={perpage} id="rows" onInput={
                            (e) => 
                            {dispatch(setPerPage(e.target.value));
                            dispatch(setPagesList(1))}}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                        </select>
                    </div>
                </div>
                <SlidePage datalen={lengthFiltered}/>
                <GrillaTemplates/>
                <SlidePage datalen={lengthFiltered}/>
            </div>
        </div>
    )
}

export default TemplatesMade