import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import '../../static/css/GrillaControls.css'

const GrillaEmpresas = () => {
    
    const { filteredData } = useSelector(state => state.dataEmpresas)
    const { listPage: pages } = useSelector(state => state.pages)
    const { perPage } = useSelector(state => state.perPage)

    const [ empresa, setEmpresa] = useState('');
    const [ inputNombre, setInputNombre ] = useState('')

    const getData = (page, per_page) => {
        const start_index = page - 1;
        const start = start_index * per_page;
        const total_amount = (page * per_page);
        return filteredData.slice(start, total_amount)
    }
    
    const value = getData(pages, perPage);

    const showEditModal = (emp) => {
        setEmpresa(emp)
        setInputNombre(emp.Nombre)
        const editDialog = document.getElementById("editDialog");
        editDialog.showModal();
    }

    const showDeleteModal = (emp) => {
        setEmpresa(emp)
        const deleteDialog = document.getElementById("deleteDialog");
        deleteDialog.showModal();
    }

    return (
        <div style={{overflowX: "auto"}}>

            <dialog className='dialog' id="editDialog">  
                <form>
                    <div className='titleModal'>
                        <h4>Editar Empresa</h4>
                    </div>
                    <div className="contentModal">
                        <label className='inputModal'>Nombre:
                            <input className='inputSearchModal' value={inputNombre} type="text" onChange={(e) => setInputNombre(e.target.value)}></input>
                        </label>
                    </div>
                    <div className='buttonsModal'>
                        <button className='buttonModal' value="cancel" formMethod="dialog">Cancelar</button>
                        <button className='buttonModal' style={{marginLeft: '0.5rem'}} id="confirmBtn" value="default">Confirmar</button>
                    </div>
                </form>
            </dialog>

            <dialog className='dialog' id="deleteDialog">  
                <form>
                    <div className='titleModal'>
                        <h4>Eliminar Empresa</h4>
                    </div>
                    <div className="contentModal">
                        <p>¿Realmente desea eliminar la Empresa: <b>{empresa.Nombre}</b>?</p>
                    </div>
                    <div className='buttonsModal'>
                        <button className='buttonModal' value="cancel" formMethod="dialog">Cancelar</button>
                        <button className='buttonModal' style={{marginLeft: '0.5rem'}} id="confirmBtn" value="default">Eliminar</button>
                    </div>
                </form>
            </dialog>

            <table className="table">
                <thead>
                    <tr className='headers'>
                        <th className="headersTextmin" width="10%">#</th>
                        <th className="headersText" width="50%">Nombre</th>
                        <th className="headersText" width="40%"></th>
                    </tr>
                </thead>
                <tbody>
                { value.length === 0 
                ? (
                    <tr>
                        <td className="headersText">Sin registros</td>
                    </tr>
                )
                : value.map((element)=>{
                    return(
                        <tr key={element.idEmpresa} className="fraction">
                            <td className="tdTextmin" width="10%" style={{textAlign: "left"}}>{element.idEmpresa}</td>
                            <td className="tdText" width="50%" style={{textAlign: "left"}}>{element.Nombre}</td>
                            <td className="tdText" width="40%">
                                <button className="buttonDetails" onClick={() => showEditModal(element)}>Editar</button>
                                <button className="buttonDetails" style={{marginLeft: "0.5rem", backgroundColor: "#FF9B85"}} onClick={() => showDeleteModal(element)}>Eliminar</button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
                <tfoot>
                    <tr className='spaceWithe' >
                        <td>
                            <br />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default GrillaEmpresas