import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setDataTempFilt } from '../../store/slices/dataTemplates/dataTemplates'
import { setPagesList } from '../../store/slices/pages/pages'
import { validateRangeDates } from '../../utils/utils.js'
import '../../static/css/PanelSelectorTemplates.css'

const PanelSelectorTemplates = () => {
    const { allData : data} = useSelector(state=>state.dataTemplates)
    const dispatch = useDispatch()

    const now = new Date().toLocaleDateString('en-CA')
    const maxRangeDate = 2 //months

    const [ disp, setDisp ] = useState('')
    const [ dateFrom, setDateFrom ] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString('en-CA'))
    const [ dateTo, setDateTo ] = useState(now)

    const uniqueDisp = Array.from(new Set(data.map((template) => template.Dispositivo)))

    useEffect(() => {
        const fechaDesde = new Date(`${dateFrom}T00:00:00`);
        const fechaHasta = new Date(`${dateTo}T23:59:59`);
        const sortedData = data.slice().sort((a, b) => new Date(b.FechaDeCreacion).getTime() - new Date(a.FechaDeCreacion).getTime())
        const filtered = sortedData.filter(item => {
            const fechaCreacion = new Date(item.FechaDeCreacion);
            if(disp !== '') {
                return (fechaCreacion >= fechaDesde && fechaCreacion <= fechaHasta && item.Dispositivo === disp)
            }
            return (fechaCreacion >= fechaDesde && fechaCreacion <= fechaHasta)
        });
        dispatch(setPagesList(1));
        dispatch(setDataTempFilt(filtered));
    }, [dateFrom, dateTo, disp, data, dispatch])

    const checkDates = () => {
        const FD = document.getElementById("FechaDesde");
        const FH = document.getElementById("FechaHasta");

        if(validateRangeDates(FD.value, FH.value, maxRangeDate)){
            FD.setCustomValidity("")
        }
        else FD.setCustomValidity("Rango de fechas superior a 2 meses")
    }

    return (
        <form className="filters" method="post" onSubmit={(e)=>{
            e.preventDefault()
            if(validateRangeDates(e.target[0].value, e.target[1].value, maxRangeDate)) {
                setDateFrom(e.target[0].value)
                setDateTo(e.target[1].value)
                setDisp(e.target[2].value)
            }
        }}>
    
            <div className="datefilter">
                <label className='m-2 ' htmlFor='FechaDesde'>Desde</label>
                <input id="FechaDesde" className="input" 
                    type="date" defaultValue={dateFrom} onChange={() => checkDates()} min="2010-01-01" max={now} 
                    required
                />

                <label className='m-2' htmlFor='FechaHasta'>Hasta</label>
                <input id='FechaHasta' className="input" 
                    type="date" defaultValue={dateTo} onChange={() => checkDates()} min="2010-01-01" max={now} 
                    required
                />
            </div>

            <div className="dispfilter">
                <label className='m-2' htmlFor='dispositivo'>Dispositivo:</label>
                <select id='dispositivo' className="selectDisp" defaultValue={disp}>
                    <option key={0} value="">Todos</option>
                    {uniqueDisp.map( (dispositivo, index) => {
                        return ( 
                            <option key={index+1} value={dispositivo}>{dispositivo}</option>
                        )
                    }

                    )}
                </select>
                <button type="submit" className="buttonClickTemplates">Buscar</button>
            </div>

            
        </form>
    )
}

export default PanelSelectorTemplates