import {createSlice} from '@reduxjs/toolkit'
import json from '../../../static/data/dataGrillaUsuarios.json'

export const receptionDataUsuarios = createSlice({
  name:'dataUsuarios',
  initialState:{
    allData: json.data,
    filteredData: json.data
  },
  reducers:{
    setAllDataUsuarios : (state, action)=>{
      state.allData = action.payload
    },
    setFilteredUsuarios : (state, action)=>{
      state.filteredData = action.payload
    }
  }
})

export const { setAllDataUsuarios, setFilteredUsuarios } = receptionDataUsuarios.actions;
export default receptionDataUsuarios.reducer;