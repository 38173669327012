import React from 'react'
import '../../static/css/footer.css'

const Footer = () => {

    return (
      <div className="footer">
        <div className='contentFooter'>
              <p style={{margin: '0'}}>Ecofor Servicios Tecnológicos</p>
              <p style={{margin: '0'}}>O`Higgins 241, Piso 11 Of. 1124, Concepción, Chile</p>
              <p style={{margin: '0'}}>Fono +56 41 332 03 00</p>
        </div>
      </div>
    )
}

export default Footer