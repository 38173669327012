import {createSlice} from '@reduxjs/toolkit'
import json from '../../../static/data/dataGrillaDispositivos.json'

export const receptionDataDisp = createSlice({
  name:'dataDisp',
  initialState:{
    allData: json.data,
    filteredData: json.data
  },
  reducers:{
    setAllDataDisp : (state, action)=>{
      state.allData = action.payload
    },
    setFilteredDisp : (state, action)=>{
      state.filteredData = action.payload
    }
  }
})

export const { setAllDataDisp, setFilteredDisp } = receptionDataDisp.actions;
export default receptionDataDisp.reducer;