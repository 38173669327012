import React, { useState } from 'react'
import logo from '../../static/images/logo-ecofor-btpack.svg'
import menu from '../../static/images/menu-64.png'
import '../../static/css/navbar.css'

const Navbar = () => {

  return (
    <div className='contentNav'>
      <div className='subContentNav'>
        <img className="logoEcofor" src={logo} alt=""/>
        <div className="contentMenu">
          <div className="dropdown">
            <button className="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Ecofor User
              <img className="menuImage" src={menu} alt=""/>
            </button>
            <ul className="dropdown-menu dropdown-menu-dark">
              <li><a className="dropdown-item" href="#">Action</a></li>
              <li><a className="dropdown-item" href="#">Cerrar Sesión</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar