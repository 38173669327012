import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import Arrow from '../../static/images/arrowBottom.png'
import '../../static/css/Sidebar.css'

const Sidebar = ({page}) => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed)
    }

    return(
        <>
            <div className={collapsed ? "toggleButtonOpen" : "toggleButtonClose"} onClick={toggleCollapsed}>
                <img className='arrow' style={{transform: !collapsed ? "rotate(-90deg)" : "rotate(90deg)"}} src={Arrow} alt=""></img>
            </div>

            <div className={collapsed ? "sidebarOpen" : "sidebarClose"}>
                <div className='content'>
                    <ul className='ulist'>
                        <li className='liSidebar'><Link className='link' style={{color: page === "viewcontrol" ? "#ffc107" : null}} to= '/viewcontrol'>Controles Emitidos</Link></li>
                        <li className='liSidebar'><Link className='link' style={{color: page === "report" ? "#ffc107" : null}} to= '/report'>Reporte Consolidado</Link></li>
                        <li className='liSidebar'><Link className='link' style={{color: page === "templates" ? "#ffc107" : null}} to= '/templates'>Plantillas Creadas</Link></li>
                        <li className='liSidebar'></li>
                    </ul>
                </div>
            </div>    
        </>
    )
}

export default Sidebar