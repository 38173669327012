import {createSlice} from '@reduxjs/toolkit'
import json from '../../../static/data/DataGrilla.json'

export const receptionData = createSlice({
  name:'data',
  initialState:{
    allData: json.data.slice().sort((a, b) => new Date(b.FechaDeControl).getTime() - new Date(a.FechaDeControl).getTime()),
    filteredData: json.data.slice().sort((a, b) => new Date(b.FechaDeControl).getTime() - new Date(a.FechaDeControl).getTime())
  },
  reducers:{
    setAllData : (state, action)=>{
      state.allData = action.payload
    },
    setFiltered : (state, action)=>{
      state.filteredData = action.payload
    }
  }
})

export const { setAllData, setFiltered } = receptionData.actions;
export default receptionData.reducer;











    