import React, {useState, useEffect} from 'react'

const Home = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState("")

  useEffect(() => {
    fetch('https://070dr6hbu5.execute-api.us-east-2.amazonaws.com/miFuncionGo')
         .then((response) => {
          return response.text()
        })
         .then((data) => {
          setResponse(data)
          setIsLoading(false)
         })
  }, [])

  return (
    <div style={{minHeight: '75vh'}}>
      {isLoading ? 
        (
          <p>Cargando...</p>
        )
        : (
          <p>{response}</p>
        )
        }
    </div>
  )
}

export default Home