import React, {useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'
import arrowRigt from '../static/images/arrowRight.png'
import arrowRight from '../static/images/8666713_arrow_up_right_icon.png'
import arrowLeft from '../static/images/8666713_arrow_down_left_icon.png'
import circleIcon from '../static/images/8665140_bullseye_target_icon.png'
import arrowTop from '../static/images/8542606_sort_amount_up_alt_icon.png'
import '../static/css/styleControlDetail.css'
import Highcharts from 'highcharts'
import highchartsVariablePie from "highcharts/modules/variable-pie";
import HighchartsReact from 'highcharts-react-official'
import { useSelector } from 'react-redux'
import json from '../static/data/dataControl.json'
import Sidebar from '../componentes/controls/Sidebar'
import { decimalWithComma } from '../utils/utils.js'

const ViewControlDetail = () => {

    const {id} = useParams()

    const { allData: data } = useSelector(state => state.data)
    const { allData: templates } = useSelector(state => state.dataTemplates)

    const control = data.find(element => element.nControl == id)
    const plantilla = templates.find(element => element.Plantilla == control.Plantilla)

    const [ type, setType ] = useState (plantilla.Medidas[0].Nombre)

    const measureTemplate = plantilla.Medidas.find(element => element.Nombre == type)

    const validType = measureTemplate !== undefined

    useEffect(() => {
        setType(plantilla.Medidas[0].Nombre)
    }, [validType, id, plantilla.Medidas])

    const measuresControl = validType ? control.Medidas.find(element => element.Tipo == type).Valor : []

    const sobre = measuresControl.filter(i => i > measureTemplate.Objetivo + measureTemplate.ToleranciaSuperior)
    const en = measuresControl.filter(i => i >= measureTemplate.Objetivo + measureTemplate.ToleranciaInferior && i <= measureTemplate.Objetivo + measureTemplate.ToleranciaSuperior)
    const bajo = measuresControl.filter(i => i < measureTemplate.Objetivo + measureTemplate.ToleranciaInferior)

    const uniqueSobre = Array.from(new Set(sobre))
    const uniqueEn = Array.from(new Set(en))
    const uniqueBajo = Array.from(new Set(bajo))

    const style = {
        colors:{
            red: "#FF9B85",
            orange: "#FFD97D",
            green: "#60D394"
        }
    }

    const dataHist = uniqueSobre.map((i) => {return ({name: i, y: sobre.filter(e => e === i).length, color: style.colors.red})}).concat(
        uniqueEn.map((i) => {return ({name: i, y: en.filter(e => e === i).length, color: style.colors.green})}),
        uniqueBajo.map((i) => {return ({name: i, y: bajo.filter(e => e === i).length, color: style.colors.orange})})
    )

    const verificarMedidas = () => {
        var ngood = 0
        const medidas = json.data[0].Medidas
        for (let i=0; i < medidas.length; i++){
            var good = true
            for (let j=0; j < plantilla.Medidas.length; j++){
                const tipo = plantilla.Medidas[j].Nombre
                const medidaControl = medidas[i].find(element => element.Tipo == tipo)
                const valid = (value) => value < plantilla.Medidas[j].Objetivo + plantilla.Medidas[j].ToleranciaSuperior && value > plantilla.Medidas[j].Objetivo + plantilla.Medidas[j].ToleranciaInferior
                
                good = good ? medidaControl.Valor.every(valid) : false
            }
            if (good){
                ngood++;
            }
        }
        
        return ngood
    }

    const passed = verificarMedidas();

    highchartsVariablePie(Highcharts);

    const optionsColumn = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Mediciones por valor',
            align: 'center'
        },
        xAxis: {
            visible: false,
            crosshair: true
        },
        yAxis: {
            title: {
                text: 'Cantidad de Mediciones'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                }
            }
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<span>{point.name} '+ plantilla.Unidad[0].toLowerCase() + plantilla.Unidad.slice(1) +'</span> <br/> Cantidad: {point.y}'
        },
        series: [{
            data: dataHist,
            dataSorting: {
                enabled: true
            }
        }]
    }

    const optionsPie = {
        chart: {
            type: 'variablepie'
        },
        title: { 
            text:'Distribución de mediciones',
            align: 'center'
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
            'Porcentaje: <b>{point.percentage:.1f}%</b><br/>' +
            'Cantidad: <b>{point.y}</b><br/>'
        },
        series: [{
            minPointSize: 1,
            innerSize: '20%',
            zMin: 0,
            name: 'rango',
            borderRadius: 5,
            data: [{
                name: 'Bajo',
                y: bajo.length,
                z: 4
            }, {
                name: 'En',
                y: en.length,
                z: 5
            }, {
                name: 'Sobre',
                y: sobre.length,
                z: 6
            }],
            colors: [
                style.colors.orange,
                style.colors.green,
                style.colors.red
            ]
        }]
    }

    const optionsColumnTotal = {
        chart: {
            type: 'column'
        },
        title: { 
            text: 'Estado de piezas medidas',
            align: 'center'
        },
        xAxis: {
            visible: false,
            crosshair: true
        },
        yAxis: {
            title: {
                text: 'Piezas'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                }
            }
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<span>{point.name}</span> <br/> Cantidad: {point.y}'
        },
        series: [{
            data: [
                {
                    name:"Correctas",
                    y: passed,
                    color: style.colors.green
                },
                {
                    name:"Rechazadas",
                    y: json.data[0].Medidas.length - passed,
                    color: style.colors.red
                }
            ],
            dataSorting: {
                enabled: true
            }
        }]
    }

    return (
        <div style={{backgroundColor: "#fafafa",}}>
            <Sidebar page=""/>
            <div className='centerContent'>
                <p className='location'>Usted está en : Login 
                    <img src={arrowRigt} alt=""/> 
                    <Link to='/viewcontrol' className="linkLocation">Historial de Mediciones</Link> 
                    <img src={arrowRigt} alt=""/> 
                    <span className='spanLocation'>Detalle</span> </p>
                <div className='contentTitle'>
                    <h3 className='title'>Detalle de Control</h3>
                    <button className="buttonExport">Exportar Datos</button>
                </div>
                <div className='contentDataAndMeasure'>
                    <ul className='list' style={{paddingLeft: "0", marginBottom: "0"}}>
                        <li className='li'> 
                            <span className='titles' >Fecha de Control: </span> <br/>
                            <p className='textTitle'>{control.FechaDeControl}</p>
                        </li>
                        <li className='li'>
                            <span className='titles' >Dispositivo: </span> <br/>
                            <p className='textTitle'>{control.Dispositivo}</p>
                        </li>
                        <li className='li'>
                            <span className='titles' >Plantilla: </span> <br/>
                            <p className='textTitle'>{control.Plantilla}</p>
                        </li>
                        <li className='li'>
                            <span className='titles'>Unidad medida: </span> <br/>
                            <p className='textTitle'>{plantilla.Unidad}</p>
                        </li>
                        <li className='li'> 
                            <span className='titles'>Tipo de medida: </span> <br/>
                            <select id="selectorType" className="selectorType" defaultValue={type} onInput={
                                (e) => 
                                {setType(e.target.value)}}>
                                {plantilla.Medidas.map((element, index) => {
                                    return(<option key={index} value={element.Nombre}>{element.Nombre}</option>)
                                })}
                            </select>
                        </li>
                    </ul>
                </div>
                <div className='contentHistograma'>
                    <div className='trioData'>
                        <div className='contentAData'>
                            <div>
                                <img className='imgs' src={arrowTop} alt=''/>
                            </div>
                            <div>
                                <h3 className='aDataH3'>Piezas Medidas</h3>
                                <p className='bottomDate'>{control.NroMedidas}</p>
                            </div>
                        </div>

                        <div className='contentAData'>
                            <div>
                                <img className='imgs' src={arrowTop} alt=""/>
                            </div>
                            <div>
                                <h3 className='aDataH3'>Cantidad de Mediciones</h3>
                                <p className='bottomDate'>{measureTemplate.Cantidad * control.NroMedidas}</p>
                            </div>
                        </div>

                        <div className='contentAData' >
                            <div>
                                <img className='imgs' src={circleIcon} alt=""/>
                            </div>
                            <div>
                                <h3 className='aDataH3'>Objetivo Target</h3>
                                <p className='bottomDate'>{decimalWithComma(measureTemplate.Objetivo)}</p>
                            </div>
                        </div>
                    </div>

                    <div className='duoData'>
                        <div className='contentAData'>
                            <div>
                                <img className='imgs' src={arrowRight} alt=""/>
                            </div>
                            <div>
                                <h3 className='aDataH3'>Tolerancia Superior</h3>
                                <p className='bottomDate'>+{decimalWithComma(measureTemplate.ToleranciaSuperior)}</p>
                            </div>
                        </div>

                        <div className='contentAData'>
                            <div>
                                <img className='imgs' src={arrowLeft} alt=""/>
                            </div>
                            <div>
                                <h3 className='aDataH3'>Tolerancia Inferior</h3>
                                <p className='bottomDate'>{decimalWithComma(measureTemplate.ToleranciaInferior)}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='contentGraphs'>
                    <div className='graphmeasures'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsColumn}
                        />
                    </div>
                    <div className='graph'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsColumnTotal}
                        />
                    </div>
                    <div className='graph'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsPie}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ViewControlDetail