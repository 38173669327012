import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import arrowRigt from '../static/images/arrowRight.png'
import arrowRight from '../static/images/8666713_arrow_up_right_icon.png'
import arrowLeft from '../static/images/8666713_arrow_down_left_icon.png'
import circleIcon from '../static/images/8665140_bullseye_target_icon.png'
import arrowTop from '../static/images/8542606_sort_amount_up_alt_icon.png'
import '../static/css/styleControlDetail.css'
import Highcharts from 'highcharts'
import highchartsVariablePie from "highcharts/modules/variable-pie";
import HighchartsReact from 'highcharts-react-official'
import { useSelector } from 'react-redux'
import Sidebar from '../componentes/controls/Sidebar'
import { transformDate, validateRangeDates } from '../utils/utils'

const ReportBase = () => {

    const style = {
        colors:{
            red: "#FF9B85",
            orange: "#FFD97D",
            green: "#60D394"
        }
    }

    const navigate = useNavigate()

    const { allData: templates } = useSelector(state => state.dataTemplates)

    const [ dateFrom, setDateFrom ] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString('en-CA'))
    const [ dateTo, setDateTo ] = useState(new Date().toLocaleDateString('en-CA'))

    const maxRangeDate = 2 // months
    const now = new Date().toLocaleDateString('en-CA')

    highchartsVariablePie(Highcharts);

    const optionsColumn = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Mediciones por valor',
            align: 'center'
        },
        xAxis: {
            visible: false,
            crosshair: true
        },
        yAxis: {
            title: {
                text: 'Cantidad de Mediciones'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                }
            }
        },
        series: [{
            data: [],
            dataSorting: {
                enabled: true
            }
        }]
    }

    const optionsPie = {
        chart: {
            type: 'variablepie'
        },
        title: { 
            text:'Distribución de mediciones',
            align: 'center'
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
            'Porcentaje: <b>{point.percentage:.1f}%</b><br/>' +
            'Cantidad: <b>{point.y}</b><br/>'
        },
        series: [{
            minPointSize: 1,
            innerSize: '20%',
            zMin: 0,
            name: 'rango',
            borderRadius: 5,
            data: [{
                name: 'Bajo',
                y: 0,
                z: 4
            }, {
                name: 'En',
                y: 0,
                z: 5
            }, {
                name: 'Sobre',
                y: 0,
                z: 6
            }],
            colors: [
                style.colors.orange,
                style.colors.green,
                style.colors.red
            ]
        }]
    }

    const optionsColumnTotal = {
        chart: {
            type: 'column'
        },
        title: { 
            text: 'Estado de piezas medidas',
            align: 'center'
        },
        xAxis: {
            visible: false,
            crosshair: true
        },
        yAxis: {
            title: {
                text: 'Piezas'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                }
            }
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<span>{point.name}</span> <br/> Cantidad: {point.y}'
        },
        series: [{
            data: [
                {
                    name:"Correctas",
                    y: 0,
                    color: style.colors.green
                },
                {
                    name:"Rechazadas",
                    y: 0,
                    color: style.colors.red
                }
            ],
            dataSorting: {
                enabled: true
            }
        }]
    }

    const checkDates = () => {
        const FD = document.getElementById("FechaDesde");
        const FH = document.getElementById("FechaHasta");

        if(validateRangeDates(FD.value, FH.value, maxRangeDate)){
            FD.setCustomValidity("")
        }
        else FD.setCustomValidity("Rango de fechas superior a 2 meses")
    }

    return (
        <div style={{backgroundColor: "#fafafa",}}>
            <Sidebar page="report"/>
            <div className='centerContent'>
                <p className='location'>Usted está en : Login 
                    <img src={arrowRigt} alt=""/> 
                    <Link to='/templates' className="linkLocation">Plantillas Creadas</Link> 
                    <img src={arrowRigt} alt=""/> 
                    <span className='spanLocation'>Reporte</span> </p>
                <div className='contentTitle'>
                    <h3 className='title'>Reporte Consolidado</h3>
                    <button className="buttonExport">Exportar Datos</button>
                </div>
                <div className='filters'>

                    <form className="dateSelector" method="post" onSubmit={(e)=>{
                        e.preventDefault()
                        if(validateRangeDates(e.target[0].value, e.target[1].value, maxRangeDate)) {
                            setDateFrom(e.target[0].value)
                            setDateTo(e.target[1].value)
                        }
                    }}>

                        <label htmlFor="FechaDesde">Periodo: </label>
                        <input className="dateFilter" type="date" id="FechaDesde" onChange={() => checkDates()} defaultValue={dateFrom} min="2010-01-01" max={now} required></input>
                        <label htmlFor="FechaHasta">-</label>
                        <input className="dateFilter" type="date" id="FechaHasta" onChange={() => checkDates()} defaultValue={dateTo} min="2010-01-01" max={now} required></input>

                        <button type="submit" className="buttonClick">Buscar</button>
                    </form>

                    <div className='typeSelector'>
                        <label htmlFor="selectTemplate">Plantilla: </label>
                        <select id="selectTemplate" defaultValue='' className="selectTemplate" onInput={
                            (e) => {
                                e.preventDefault()
                                if(e.target.value !== '') navigate(`/report/${e.target.value}`)}
                        }>
                            <option value=''> Seleccione </option>
                            {templates.map( (temp, index) => {
                                return (
                                    <option key={index} value={temp.nPlantilla}>{temp.Plantilla}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>

                <div className='contentDataAndMeasure'>
                    <ul className='list'>
                        <li className='li'> 
                            <span className='titles' >Periodo consolidado: </span> <br/>
                            <p className='textTitle'>{transformDate(dateFrom)}-{transformDate(dateTo)}</p>
                        </li>
                        <li className='li'>
                            <span className='titles' >Dispositivo: </span> <br/>
                            <p className='textTitle'></p>
                        </li>
                        <li className='li'>
                            <span className='titles'>Unidad medida: </span> <br/>
                            <p className='textTitle'></p>
                        </li>
                        <li className='li'> 
                            <span className='titles'>Tipo de medida: </span> <br/>
                            <select id="selectType" className="selectorType">
                                <option value="">Medida</option>
                            </select>
                        </li>
                    </ul>
                </div>

                <div className='contentHistograma'>
                    <div className='trioData'>
                        <div className='contentAData'>
                            <div>
                                <img className='imgs' src={arrowTop} alt=''/>
                            </div>
                            <div>
                                <h3 className='aDataH3'>Piezas Medidas</h3>
                                <p className='bottomDate'>0</p>
                            </div>
                        </div>

                        <div className='contentAData'>
                            <div>
                                <img className='imgs' src={arrowTop} alt=""/>
                            </div>
                            <div>
                                <h3 className='aDataH3'>Cantidad de Mediciones</h3>
                                <p className='bottomDate'>0</p>
                            </div>
                        </div>

                        <div className='contentAData'>
                            <div>
                                <img className='imgs' src={circleIcon} alt=""/>
                            </div>
                            <div>
                                <h3 className='aDataH3'>Objetivo Target</h3>
                                <p className='bottomDate'>0</p>
                            </div>
                        </div>
                    </div>

                    <div className='duoData'>
                        <div className='contentAData'>
                            <div>
                                <img className='imgs' src={arrowRight} alt=""/>
                            </div>
                            <div>
                                <h3 className='aDataH3'>Tolerancia Superior</h3>
                                <p className='bottomDate'>0</p>
                            </div>
                        </div>

                        <div className='contentAData'>
                            <div>
                                <img className='imgs' src={arrowLeft} alt=""/>
                            </div>
                            <div>
                                <h3 className='aDataH3'>Tolerancia Inferior</h3>
                                <p className='bottomDate'>0</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='contentGraphs'>
                    <div className='graphmeasures'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsColumn}
                        />
                    </div>
                    <div className='graph'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsColumnTotal}
                        />
                    </div>
                    <div className='graph'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsPie}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ReportBase