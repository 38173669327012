import {createSlice} from '@reduxjs/toolkit'
import json from '../../../static/data/dataGrillaEmpresas.json'

export const receptionDataEmpresas = createSlice({
  name:'dataEmpresas',
  initialState:{
    allData: json.data,
    filteredData: json.data
  },
  reducers:{
    setAllDataEmpresas : (state, action)=>{
      state.allData = action.payload
    },
    setFilteredEmpresas : (state, action)=>{
      state.filteredData = action.payload
    }
  }
})

export const { setAllDataEmpresas, setFilteredEmpresas } = receptionDataEmpresas.actions;
export default receptionDataEmpresas.reducer;