import './App.css';
import React, {useState, useEffect} from 'react';
import { Provider } from 'react-redux';
import store from './store/store'
import Navbar from './componentes/navbar/Navbar';
import ViewControlDetail from './pages/ViewControlDetail';
import ViewControl from './pages/ViewControl';
import Home from './pages/Home';
import Report from './pages/Report';
import ReportBase from './pages/ReportBase'
import TemplatesMade from './pages/TemplatesMade';
import AdminView from './pages/AdminView'

import { BrowserRouter, Routes , Route  } from 'react-router-dom';
import Footer from './componentes/thefooter/Footer';

function App() {

  return (
    <Provider store={store} className="App bg-primary">
        <Navbar/>
        <BrowserRouter>
          <Routes>
            <Route  path='/' element={ <Home/> } />
            <Route  path='/viewcontrol' element={ <ViewControl/> } />
            <Route  path='/viewcontroldetail/:id' element={  <ViewControlDetail/> } />
            <Route  path='/report' element={ <ReportBase/> } />
            <Route  path='/report/:id' element={ <Report/> } />
            <Route  path='/templates' element={ <TemplatesMade/> } />
            <Route  path='/admin' element={ <AdminView/> } />
          </Routes>
        </BrowserRouter>
        <Footer/>
    </Provider>
  );
}

export default App;
