import React, { useState } from 'react'
import '../static/css/AdminView.css'
import GrillaEmpresas from '../componentes/controls/GrillaEmpresas'
import GrillaUsuarios from '../componentes/controls/GrillaUsuarios'
import GrillaEquipos from '../componentes/controls/GrillaEquipos'
import { useDispatch, useSelector } from 'react-redux'
import { setFilteredEmpresas } from '../store/slices/dataEmpresas/dataEmpresas'
import { setFilteredUsuarios } from '../store/slices/dataUsuarios/dataUsuarios'
import { setFilteredDisp } from '../store/slices/dataDisp/dataDisp'
import { setPagesList } from "../store/slices/pages/pages";
import { setPerPage } from '../store/slices/perPage/perPage'
import SlidePage from '../componentes/controls/SlidePage'

const AdminView = () => {

    const { allData: empresas, filteredData: empresasFiltered } = useSelector(state => state.dataEmpresas)
    const { allData: usuarios, filteredData: usuariosFiltered } = useSelector(state => state.dataUsuarios)
    const { allData: dispositivos, filteredData: dispositivosFiltered } = useSelector(state => state.dataDisp)
    const { perPage: perpage } = useSelector(state => state.perPage)

    const dispatch = useDispatch()

    const [ tab, setTab ] = useState("empresas")
    const [ search, setSearch ] = useState('')

    const [ inputEmpNombre, setInputEmpNombre ] = useState('')

    const [ inputUsuarioEmp, setInputUsuarioEmp] = useState('')
    const [ inputUsuarioNombre, setInputUsuarioNombre ] = useState('')
    const [ inputUsuarioEmail, setInputUsuarioEmail ] = useState('')

    const [ inputDispEmp, setInputDispEmp] = useState('')
    const [ inputDispNombre, setInputDispNombre ] = useState('')
    const [ inputDispEmail, setInputDispEmail ] = useState('')
    const [ inputDispID, setInputDispID ] = useState('')
    const [ inputDispFono, setInputDispFono ] = useState('')

    const nombresEmpresas = empresas.map((e) => e.Nombre)

    const filterContent = (value) => {
        setSearch(value)
        if(tab === "empresas"){
            if (value === '') dispatch(setFilteredEmpresas(empresas)) 
            else dispatch(setFilteredEmpresas(empresas.filter(empresa => empresa.Nombre === value)))
        }
        else if(tab === "usuarios"){
            if (value === '') dispatch(setFilteredUsuarios(usuarios)) 
            else dispatch(setFilteredUsuarios(usuarios.filter(usuario => usuario.empresa === value)))
        }
        else if(tab === "equipos"){
            if (value === '') dispatch(setFilteredDisp(dispositivos)) 
            else dispatch(setFilteredDisp(dispositivos.filter(dispositivo => dispositivo.empresa === value)))
        }
        dispatch(setPagesList(1))
    }

    const showCreateModal = () => {

        const createEmpresaDialog = document.getElementById("createEmpresaDialog")
        const createUserDialog = document.getElementById("createUserDialog")
        const createDispDialog = document.getElementById("createDispDialog")

        if(tab === 'empresas'){
            setInputEmpNombre('')
            createEmpresaDialog.showModal()
        }
        else if(tab === "usuarios"){
            setInputUsuarioNombre('')
            setInputUsuarioEmail('')
            setInputUsuarioEmp('')
            createUserDialog.showModal()
        }
        else if(tab === "equipos"){
            setInputDispID('')
            setInputDispNombre('')
            setInputDispEmail('')
            setInputDispFono('')
            setInputDispEmp('')
            createDispDialog.showModal()
        }
    }

    const handleTabChange = (tabname) => {
        setTab(tabname)
        filterContent('')
        dispatch(setPagesList(1))
    }

    const Filter = () => {
        return(
            <div className='filter'>
                <form method="post" onSubmit={(e)=>{
                    e.preventDefault()
                    filterContent(e.target[0].value)
                }}>
                    <label htmlFor="search">Búsqueda:</label>
                    <input className="inputSearch" placeholder='Ingresar Empresa' defaultValue={search} id="search" type="search" list="empresas"></input>
                    <datalist id="empresas">
                        {empresas.map(e => {
                        return (
                            <option key={e.Nombre} value={e.Nombre}>{e.Nombre}</option>
                        )})}
                    </datalist>
                    <button className='buttonSearch' type="submit">Buscar</button>
                </form>  
            </div>
        )
    }

    const TabContent = () => {
        if(tab === "empresas"){
            return (
                <div className="backgroundView">
                    <div style={{borderBottom: '1px solid #ddd'}}>
                        <ul className='navtabs'>
                            <li className='navtabSelected'>Empresas</li>
                            <li className='navtab' onClick={() => handleTabChange("usuarios")}>Usuarios</li>
                            <li className='navtab' onClick={() => handleTabChange("equipos")}>Equipos móviles</li>
                        </ul>
                    </div>
                    <div className='titlediv'>
                        <h2 className="title">Administración de empresas</h2>
                    </div>
                    <div className="contentFilter">
                        <Filter/>
                        <button className='buttonCreate' onClick={() => showCreateModal()}>Crear Empresa</button>
                    </div>
                    <div className='select'>
                        <label htmlFor="rows">Registros por página:</label>
                        <select className='selectPages' defaultValue={perpage} id="rows" onInput={
                            (e) => 
                            {dispatch(setPerPage(e.target.value));
                            dispatch(setPagesList(1))}}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                        </select>
                    </div>
                    <SlidePage datalen={empresasFiltered.length}/>
                    <GrillaEmpresas/>
                </div>
            )
        }
        else if(tab === "usuarios"){
            return (
                <div className="backgroundView">
                    <div style={{borderBottom: '1px solid #ddd'}}>
                        <ul className='navtabs'>
                            <li className='navtab' onClick={() => handleTabChange("empresas")}>Empresas</li>
                            <li className='navtabSelected'>Usuarios</li>
                            <li className='navtab' onClick={() => handleTabChange("equipos")}>Equipos móviles</li>
                        </ul>
                    </div>
                    <div className='titlediv'>
                        <h2 className="title">Administración de usuarios</h2>
                    </div>
                    <div className="contentFilter">
                        <Filter/>
                        <button className='buttonCreate' onClick={() => showCreateModal()}>Crear Usuario</button>
                    </div>
                    <div className='select'>
                        <label htmlFor="rows">Registros por página:</label>
                        <select className='selectPages' defaultValue={perpage} id="rows" onInput={
                            (e) => 
                            {dispatch(setPerPage(e.target.value));
                            dispatch(setPagesList(1))}}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                        </select>
                    </div>
                    <SlidePage datalen={usuariosFiltered.length}/>
                    <GrillaUsuarios nombresEmpresas={nombresEmpresas}/>
                </div>
            )
        }
        else if(tab === "equipos"){
            return (
                <div className="backgroundView">
                    <div style={{borderBottom: '1px solid #ddd'}}>
                        <ul className='navtabs'>
                            <li className='navtab' onClick={() => handleTabChange("empresas")}>Empresas</li>
                            <li className='navtab' onClick={() => handleTabChange("usuarios")}>Usuarios</li>
                            <li className='navtabSelected'>Equipos móviles</li>
                        </ul>
                    </div>
                    <div className='titlediv'>
                        <h2 className="title">Administración de equipos móviles</h2>
                    </div>
                    <div className="contentFilter">
                        <Filter/>
                        <button className='buttonCreate' onClick={() => showCreateModal()}>Crear Equipo</button>
                    </div>
                    <div className='select'>
                        <label htmlFor="rows">Registros por página:</label>
                        <select className='selectPages' defaultValue={perpage} id="rows" onInput={
                            (e) => 
                            {dispatch(setPerPage(e.target.value));
                            dispatch(setPagesList(1))}}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                        </select>
                    </div>
                    <SlidePage datalen={dispositivosFiltered.length}/>    
                    <GrillaEquipos nombresEmpresas={nombresEmpresas}/>
                </div>
            )
        }
        else{
            return null
        }
    }
        

    return(
        <div className='contentViewContol'>

            <dialog className='dialog' id="createEmpresaDialog" >
                <form >
                    <div className='titleModal'>
                        <h4>Crear Empresa</h4>
                    </div>
                    <div className="contentModal">
                        <label className='inputModal' htmlFor='crearEmpresaNombre'>Nombre:
                            <input className='inputSearchModal' id="crearEmpresaNombre" value={inputEmpNombre} onChange={(e) => setInputEmpNombre(e.target.value)} type="text"></input>
                        </label>
                    </div>
                    <div className='buttonsModal'>
                        <button className='buttonModal' value="cancel" formMethod="dialog">Cancelar</button>
                        <button className='buttonModal' style={{marginLeft: '0.5rem'}} id="confirmBtn" value="default">Confirmar</button>
                    </div>
                </form>
            </dialog>

            <dialog className='dialog' id="createUserDialog">
                <form>
                    <div className='titleModal'>
                        <h4>Crear Usuario</h4>
                    </div>
                    <div className="contentModal">
                        <label className='inputModal' htmlFor='crearUsuarioNombre'>Nombre:
                            <input className='inputSearchModal' id="crearUsuarioNombre" value={inputUsuarioNombre} onChange={(e) => setInputUsuarioNombre(e.target.value)} type="text"></input>
                        </label>

                        <label className='inputModal' htmlFor='crearUsuarioEmail'>E-mail:
                            <input className='inputSearchModal' id="crearUsuarioEmail" value={inputUsuarioEmail} onChange={(e) => setInputUsuarioEmail(e.target.value)} type="email"></input>
                        </label>

                        <label className='inputModal' htmlFor='crearUsuarioEmpresa'>Empresa:
                            <select className='inputSearchModal' id="crearUsuarioEmpresa" value={inputUsuarioEmp} onChange={(e) => setInputUsuarioEmp(e.target.value)} type="text">
                            <option key='' value=''>Seleccione</option>
                                {nombresEmpresas.map((nombre) => { return(
                                    <option key={nombre} value={nombre}>{nombre}</option>
                                )})}
                            </select>
                        </label>
                    </div>
                    <div className='buttonsModal'>
                        <button className='buttonModal' value="cancel" formMethod="dialog">Cancelar</button>
                        <button className='buttonModal' style={{marginLeft: '0.5rem'}} id="confirmBtn" value="default">Confirmar</button>
                    </div>
                </form>
            </dialog>

            <dialog className='dialog' id="createDispDialog">
                <form>
                    <div className='titleModal'>
                        <h4>Crear Equipo</h4>
                    </div>
                    <div className="contentModal">
                        <label className='inputModal' htmlFor='crearEquipoID'>ID Equipo:
                            <input className='inputSearchModal' id='crearEquipoID' value={inputDispID} onChange={(e) => setInputDispID(e.target.value)} type="text"></input>
                        </label>

                        <label className='inputModal' htmlFor='crearEquipoNombre'>Nombre:
                            <input className='inputSearchModal' id='crearEquipoNombre' value={inputDispNombre} onChange={(e) => setInputDispNombre(e.target.value)} type="text"></input>
                        </label>

                        <label className='inputModal' htmlFor='crearEquipoEmail'>E-mail:
                            <input className='inputSearchModal' id='crearEquipoEmail' value={inputDispEmail} onChange={(e) => setInputDispEmail(e.target.value)} type="email"></input>
                        </label>

                        <label className='inputModal' htmlFor='crearEquipoFono'>Teléfono:
                            <input className='inputSearchModal' id='crearEquipoFono' value={inputDispFono} onChange={(e) => setInputDispFono(e.target.value)} type="tel"></input>
                        </label>
    
                        <label className='inputModal' htmlFor='crearEquipoEmpresa'>Empresa:
                            <select className='inputSearchModal' id='crearEquipoEmpresa' value={inputDispEmp} onChange={(e) => setInputDispEmp(e.target.value)} type="text">
                                <option key='' value=''>Seleccione</option>
                                {nombresEmpresas.map((nombre) => { return(
                                    <option key={nombre} value={nombre}>{nombre}</option>
                                )})}
                            </select>
                        </label>
                    </div>
                    <div className='buttonsModal'>
                        <button className='buttonModal' value="cancel" formMethod="dialog">Cancelar</button>
                        <button className='buttonModal' style={{marginLeft: '0.5rem'}} id="confirmBtn" value="default">Confirmar</button>
                    </div>
                </form>
            </dialog>
            <TabContent />
        </div>
    )
}

export default AdminView