export function decimalWithComma(number) {
    return number.toString().replace('.',',')
}

export function transformDate(date) {
    const newdate = new Date(date)
    const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC'};
    const transformDate = newdate.toLocaleDateString('es', options)

    return transformDate
}

export function validateRangeDates(from, to, maxRangeDate) {
    const d1 = new Date(from)
    const d2 = new Date(to)

    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= maxRangeDate  ? true : false;
}
