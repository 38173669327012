import {createSlice} from '@reduxjs/toolkit'
import json from '../../../static/data/dataTemplates.json'

export const receptionDataTemp = createSlice({
  name:'dataTemplates',
  initialState:{
    allData: json.data.slice().sort((a, b) => new Date(b.FechaDeCreacion).getTime() - new Date(a.FechaDeCreacion).getTime()),
    filteredData: json.data.slice().sort((a, b) => new Date(b.FechaDeCreacion).getTime() - new Date(a.FechaDeCreacion).getTime())
  },
  reducers:{
    setDataTemp : (state, action)=>{
      state.allData = action.payload
    },
    setDataTempFilt : (state, action)=>{
      state.filteredData = action.payload
    },
  }
})

export const { setDataTemp, setDataTempFilt } = receptionDataTemp.actions;
export default receptionDataTemp.reducer;